import React, { Component } from "react";
import "./Skills.css";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        {skills.data.map((skill, i) => {
          return (
            <div key={i} className="skills-main-div">
              <div>
                <Fade left duration={1000}>
                  <h1 className="skills-heading" style={{ color: "#350505"  }}>
                    <span key={skill.title} style={{borderBottom: "4px solid #fca326"}}>
                    {skill.title}
                    </span>
                  </h1>

                </Fade>

                <Fade left duration={2000}>
                  <div>
                    {skill.skills.map((skillSentence, i) => {
                      return (
                        <>
                          <p
                            key={i}
                            className="subTitle skills-text"
                            style={{ color: theme.secondaryText }}
                          >
                            {skillSentence}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </Fade>
              </div>
                <Fade right duration={2000}>
                <div className="skills-image-div">
                  <img
                    alt="image"
                    src={require(`../../assests/images/${skill.fileName}`)}
                  ></img>
                </div>
                </Fade>
              </div>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
