import React, { Component } from "react";
import Header from "../../components/header/Header";
import TopButton from "../../components/topButton/TopButton";
import "../contact/ContactComponent.css";
import Footer from "../../components/footer/Footer.js";
import { projects } from "../../portfolio.js";
import Project from "../../containers/projects/Projects";
class Services extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <>
      <div className="contact-main">
        <Header theme={theme} />
         <div className="repo-cards-div-main" style={{marginTop: "-1rem"}}>
          {projects.projects.length > 0 ? (
            <Project theme={this.props.theme} />
          ) : null}
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
      </>
    );
  }
}

export default Services;
