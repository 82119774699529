import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Contact.css";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import ExperienceImg from "./AddressImg.js";
import TalkCard from "../../components/talkCard/TalkCard.js";
const words = "Contact Us".split(" ");
const lwords = "Find Us".split(" ");
class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <>
        <Header theme={theme} />
        <div className="experience-main">
          <div className="basic-experience">
            <Fade bottom duration={2000} distance="40px">
               <div className="experience-heading-div">
                <div className="experience-heading-img-div">
                  <ExperienceImg theme={theme} />
                </div>
                <div className="experience-heading-text-div">
                  <h1
                    className="experience-heading-text"
                    style={{ color: theme.text }}
                  >
                    {/* Contact Us */}
                    {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                  </h1>
                  <SocialMedia theme={theme} />
                  <TalkCard />
                </div>
              </div>
              <div className="skill-header-div">
                <h1
                  className="skills-header"
                  style={{ color: theme.text }}
                >
                  {/* Find Us */}
                  {lwords.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.494490705015!2d74.87138767481417!3d24.468317860837935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39667383b5adf315%3A0xcac0b469e93359dc!2sSmiley%20Rainbow%20School%20-%20Neemuch%E2%80%99s%20Premier%20School%20for%20Young%20Minds!5e0!3m2!1sen!2sin!4v1716554633934!5m2!1sen!2sin"
                  className="responsive-iframe"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Fade>
          </div>
          <TopButton theme={this.props.theme} />
        </div>
        <Footer theme={theme} />
      </>
    );
  }
}

export default Experience;
