import React, { Component, createRef } from "react";
import emailjs from "@emailjs/browser";
import "./TalkCard.css";

export default class TalkCard extends Component {
  constructor(props) {
    super(props);
    this.form = createRef();
  }

  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_51hexuu', 'template_0kx0hk5', this.form.current, '_yQgUMhMQybCmQcZU')
      .then(
        (result) => {
          console.log(result.text);
          alert('Email sent successfully!');
          this.form.current.reset();
        },
        (error) => {
          console.log(error.text);
          alert('Failed to send email.');
        }
      );
  };

  render() {
    return (
      <div className="container">
        <div className="rectangle">
          <div className="mask"></div>
          <div className="talk-card-title" style={{color:"darkBlue" , fontStyle:"bold"}}>Enquire now for Admissions</div>
          {/* <p className="talk-card-subtitle">Submit your details and we will get back to you.</p> */}

          {/* Form Section */}
          <form ref={this.form} onSubmit={this.sendEmail} className="talk-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="from_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="from_mail" required />
            </div>
            <div className="form-group">
              <label htmlFor="number">Phone No.</label>
              <input type="number" id="number" name="from_phone" required />
            </div>
            <div className="form-group">
              <label htmlFor="number">Student's Age</label>
              <input type="number" id="number" name="from_age" required />
            </div>
            <div className="card-footer-button-div">
              <input type="submit" value="Submit" className="talk-button" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
