import React, { Component } from 'react';
import './ExperienceCard.css';

class ExperienceCard extends Component {
  render() {
    const experience = this.props.experience;
    const theme = this.props.theme;
    return (
            <div className="experience-card" style={{ background: `${theme.body}` }}>    
                {experience["description"]}
              </div>

        );
  }
}

export default ExperienceCard;