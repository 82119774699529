import React, { Component } from "react";
import "../skills/Skills.css";
import { Fade } from "react-reveal";
import {Link} from "react-router-dom";
class GallerySection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="mainrequest">
        <Fade right duration={2000}>
          <div>
            <Link to="/gallery">
            <img
              alt="gallery"
              src={require(`../../assests/images/Gallery Website.png`)}
            />
            </Link>
          </div>
        </Fade>
      </div>
    );
  }
}

export default GallerySection;
