import React, { Component } from "react";
import "../skills/Skills.css";
import { Fade } from "react-reveal";

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="mainrequest">
        <Fade right duration={2000}>
          <div>
            <img
              className=""
              alt="Progression"
              src={require(`../../assests/images/progression.png`)}
            />
          </div>
        </Fade>
      </div>
    );
  }
}

export default SkillSection;
