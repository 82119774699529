import React, { Component } from "react";
import Header from "../../components/header/Header.js";
import TopButton from "../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./Projects.css";
import "../contact/ContactComponent.css";
import { contactPageData } from "../../portfolio";
import Footer from "../../components/footer/Footer.js";
const ContactData = contactPageData.contactSection;
const words = "About Us".split(" ");
const mision = "Our Mission".split(" ");
const vision = "Our Aim".split(" ");
const paragraphs = [
  
  "<strong>⭐ Instilling the Value of Learning:</strong>We instill the value of learning in each student and prepare them to be future leaders of society.",
  "<strong>⭐ High-Quality Holistic Education: </strong>We provide an exciting, high-quality, and holistic education that nurtures every aspect of a child's development.  ",
  "<strong>⭐ Innovative Personalized Learning: </strong> We demonstrate high quality with innovative technology to personalize learning for all students, ensuring each child's unique needs are met.",
  "<strong>⭐ Building Sustainable Future Leaders:</strong>We build leaders for a sustainable future, equipping students with the skills and knowledge to make a positive impact on the world.",
  "<strong>⭐ Creating a Positive Community:</strong> We create a positive, safe, and nurturing environment that inspires a strong sense of community and belonging among students, staff, and parents.",
  "<strong>⭐ Fostering Critical Thinking:</strong> We develop the ability of critical thinking and logical judgment in our students, preparing them to solve problems and make informed decisions.",
  "<strong>⭐ Encouraging Creative Inquiry:</strong> We encourage creative, enquiring minds, fostering a love for exploration and discovery in every child."
];
class About extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">

              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify" }}

                >
                  {ContactData["description"]}
                </p>
              </div>
            </div>
          </Fade>
           <div className="contact-heading-div" style={{ marginTop: "2%" }}>
            <div className="contact-heading-img-div">
              <Fade left duration={2000} distance="20px">
                <img
                  alt="Logo"
                  src={require("../../assests/images/aim.png")}
                ></img>
              </Fade>
            </div>
            <div className="contact-heading-text-div">
              <Fade right duration={2000} distance="20px">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {vision.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <p className="contact-header-detail-text subTitle" style={{ color: theme.secondaryText }}>
                At Smiley Rainbow School, we are committed to:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </Fade>
            </div>
          </div>
          <div className="contact-heading-div" style={{ marginTop: "2%" }}>
            {/* <div className="contact-heading-img-div">
              <Fade left duration={2000} distance="20px">
                <img
                  alt="Logo"
                  src={require("../../assests/images/2.png")}
                ></img>
              </Fade>
            </div> */}
            <div className="contact-heading-text-div">
              <Fade right duration={2000} distance="20px">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {mision.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <p className="contact-header-detail-text subTitle" style={{ color: theme.secondaryText , fontSize: "17px"}}>
                  Our mission is to provide high-quality education and childcare in a safe, respectful, and inclusive environment. We aim to build a strong foundation for lifelong learning, ensuring every child feels valued and supported as they grow and explore.
                </p>
              </Fade>
            </div>
          </div>
        
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default About;
