// TypingAnimation.js
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const TypingAnimation = () => {
  const [text, setText] = useState("");
  const messages = [
    "Website Development!",
    "E-commerce Solution!",
    "SEO!",
    "Digital Marketing!",
    "Graphic Designing!",
    "Content Writing!",
  ];
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setText((prevText) => {
        const currentMessage = messages[currentMessageIndex];

        if (currentCharIndex <= currentMessage.length) {
          const slicedText = currentMessage.slice(0, currentCharIndex);
          setCurrentCharIndex((prevIndex) => prevIndex + 1);
          return slicedText;
        } else {
          clearInterval(typingInterval);
          setTimeout(() => {
            // Clear text after a delay
            setText("");
            setCurrentCharIndex(0);
            setCurrentMessageIndex(
              (prevIndex) => (prevIndex + 1) % messages.length
            );
          }, 1000);
        }
        return prevText;
      });
    }, 100); // Adjust the typing speed here

    return () => clearInterval(typingInterval);
  }, [messages, currentMessageIndex, currentCharIndex]);

  return <StyledTypingAnimation>{text}</StyledTypingAnimation>;
};

const blink = keyframes`
  50% {
    border-right-color: transparent;
  }1
`;

// StyledTypingAnimation component
const StyledTypingAnimation = styled.div`
  font-family: 'system-ui', monospace;
  margin-top: 5px;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  color: #a8731b;
  margin-left: 25px;
  &::after {
    content: '|';
    display: inline-block;
    color:  orange
    animation: ${blink} 0.8s infinite;
  }
`;

export default TypingAnimation;
