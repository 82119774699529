const settings = {
  isSplash: false,
};

const seo = {
  title: "Smiley Rainbow School - Neemuch’s Premier School for Young Minds",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
};

//Home Page
const greeting = {
  title: "Smiley Rainbow School",
  logo_name: "DigitalEase",
  logo_path: "cbse_logo.png",
  subTitle:
    "Welcome to Smiley Rainbow School, Nimach’s top choice for young minds. We nurture future leaders today.🚀✨",
  resumeLink: "https://drive.google.com/",
  githubProfile: "https://github.com/sanjanas6",
  portfolio_repository: "/contact",
};

const socialMediaLinks = [
  {
    "name": "Youtube",
    "link": "https://www.youtube.com/channel/UCJ8kmFrUsidBUdsz3T4lmFA",
    "fontAwesomeIcon": "fa-brands fa-youtube",
    "backgroundColor": "#FF0000"
  },
  {
    name: "Gmail",
    link: "mailto:smileyrainbow93@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Instagram",
    link:
      "https://www.instagram.com/smileyrainbow_nmh?igsh=MTdtOGMwMDh0ZjEweQ%3D%3D",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
  {
    name: "Whatsapp",
    link: "https://api.whatsapp.com/send?phone=7000462826&text=Hello",
    fontAwesomeIcon: "fa-whatsapp",
    backgroundColor: "#181717",
  },
];

const skills = {
  data: [
    {
      title: "Circle Time",
      fileName: "8.png",
      skills: [
        "Circle Time activity promotes social interaction, emotional intelligence, and communication skills through group discussions and interactive activities. It fosters a sense of community, improves listening abilities, and encourages positive behavior, making learning a collaborative and enjoyable experience for young learners.",
        "✔️ Enhances Social Interaction",
        "✔️ Boosts Emotional Intelligence",
        "✔️ Improves Communication Skills"
       ],
    },
    {
      title: "Abacus",
      fileName: "1.png",
      skills: [
        "The Abacus activity enhances students' mathematical skills and mental agility through interactive and engaging learning methods. It helps in developing concentration, memory, and problem-solving abilities, making math fun and accessible for young learners.",
        " ✔️Enhances Mathematical Skills",
        "✔️ Boosts Mental Agility",
        "✔️ Increases Concentration"
      ],
    },
    {
      title: "Dance & Music",
      fileName: "3.png",
      skills: [
        "Our Dance & Music program nurtures creativity and emotional growth while promoting physical fitness and cultural awareness. Students enjoy a balanced and enriching experience through these artistic activities.",
        "✔️ Creative Expression",
        "✔️ Physical Fitness",
        "✔️ Cognitive Skills:",
      ],
    },
    {
      title: "Health Chekup",
      fileName: "2.png",
      skills: [
        "Our Health Checkup program ensures that students remain in optimal health, supporting their overall well-being and academic success. Regular checkups help in early detection and prevention of health issues.",
        "✔️ Early Detection",
        "✔️ Holistic Well-beings",
        "✔️ Parental Involvement",
      ],
    },
    {
      title: "Public Speaking",
      fileName: "4.png",
      skills: [
        "Our Public Speaking program empowers students to communicate confidently and effectively. It enhances their speaking skills, builds self-esteem, and prepares them for future leadership roles.",
        "✔️ Confidence Building",
        "✔️ Effective Communication",
        "✔️ Leadership Skills",
        ],
    },
    {
      title: "Cultural Diversity",
      fileName: "5.png",
      skills: [
        "Our Cultural Diversity program celebrates the rich tapestry of various cultures and traditions. By promoting inclusivity and understanding, we foster an environment where every caste and culture is honored and respected.",
        "✔️ Festival Celebrations",
        "✔️ Cultural Awareness",
        "✔️ Unity in Diversity",
      ],
    },
    {
      title: "Ethics",
      fileName: "6.png",
      skills: [
        "Our Ethics program instills strong moral values and ethical behavior in students. By teaching the importance of integrity, respect, and responsibility, we prepare students to make positive contributions to society.",
        "✔️ Moral Values",
        "✔️ Responsible Behavior",
        "✔️ Community Service",
       ],
      },
      // {
      //   title: "Martial Arts",
      //   fileName: "",
      //   skills: [
      //     "Martial Arts activity enhances physical fitness, self-discipline, and mental focus through structured training and practice. It fosters respect, builds confidence, and teaches self-defense techniques, making it a valuable and empowering experience for young learners.",
      //     "✔️ Enhances Physical Fitness",
      //     "✔️ Builds Self-Discipline",
      //     "✔️ Teaches Self-Defense Techniques"
      //    ],
      // },
      {
        title: "Yoga & Mindfullness",
        fileName: "7.png",
        skills: [
          "Our Yoga & Mindfulness program enhances students' physical and mental well-being. Through regular practice, students learn to manage stress, improve focus, and cultivate a sense of inner peace.",
          "✔️ Mental Clarity",
          "✔️ Stress Management",
          "✔️ Physical Health",
         ],
    },
  ],
};

const facility = {
 facility: [
  
  {
    title: "Theatre Zone",
    subtitle: "- Inventory Management System",
    logo_path: "f3.jpeg",
    certificate_link: "https://inventory.bookzy.in",
    color_code: "#CCCCCC66",
  },
    {
      title: "Classroom - Play to 8th Grade",
      subtitle: "- Vibrant & Playful Classes",
      logo_path: "f1.jpeg",
      certificate_link: "https://www.bookzy.in",
      color_code: "#CCCCCC66",
    },

  ],
};
const projects = {
  projects: [
    {
      title: "Sand Zone",
      subtitle: "- Playful Environment",
      logo_path: "f8.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Computer Lab",
      subtitle: "- Ed-Tech",
      logo_path: "f9.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Theatre Zone",
      subtitle: "- Entertainment",
      logo_path: "f3.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Game Zone",
      subtitle: "- Sports ",
      logo_path: "f4.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Comfort Zone",
      subtitle: "- Relax",
      logo_path: "f5.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Learning Zone",
      subtitle: "- Knowledge",
      logo_path: "f6.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Fun Zone",
      subtitle: "- Celebration",
      logo_path: "f7.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Classroom",
      subtitle: "- Vibrant & Playful Classes",
      logo_path: "f1.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "f2.jpeg",
      color_code: "#CCCCCC66",
    }
  ],
};
const gallery = {
  gallery: [
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g1.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g2.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g14.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g4.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g5.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g6.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g7.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g8.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g9.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g10.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g11.jpeg",
      color_code: "#CCCCCC66",
    },
    { title: "Library",
      subtitle: "- Reading",
      logo_path: "g12.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g13.jpeg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g3.jpg",
      color_code: "#CCCCCC66",
    },
    {
      title: "Library",
      subtitle: "- Reading",
      logo_path: "g15.jpeg",
      color_code: "#CCCCCC66",
    },
    
  ],
};

// Projects Page
const projectsHeader = {
  title: "About Us",
};

const contactPageData = {
  contactSection: {
    title: "Contact Us",
    description:
    "Welcome to Smiley Rainbow School, where we nurture young minds and build bright futures. Our dedicated and qualified staff provide a holistic education from Play School to 8th Grade under the MP Board. We focus on academic excellence, creative learning, and ethical values, ensuring each child thrives in a supportive and vibrant environment.At Smiley Rainbow School, we believe in fostering a love for learning through innovative teaching methods and a rich curriculum that includes public speaking, dance, music, martial arts, and cultural activities. Our school celebrates diversity and instills strong moral values in our students, preparing them to be responsible and compassionate global citizens.With state-of-the-art facilities, individualized attention, and a nurturing atmosphere, we create an ideal setting for children to explore, learn, and grow. Join us in shaping tomorrow's leaders today!"
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Nimach",
    country: "IN",
    region: "Madhya Pradesh",
    postalCode: "458441",
    avatar_image_path: "address_image.svg",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  facility,
  gallery,
  projects,
  projectsHeader,
  contactPageData,
};
